/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import Entrypoint from "./src/entrypoint"
import AppState from "./src/context/app-state"

function searchToObject(search) {
  var pairs = search.substring(1).split("&"),
    obj = {},
    pair,
    i

  for (i in pairs) {
    if (pairs[i] === "") continue

    pair = pairs[i].split("=")
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
  }

  return obj
}

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  const location = element.props.location
  const locSearch = searchToObject(location.search)
  const newElement = {
    ...element,
    props: {
      ...props,
      locSearch,
    },
  }
  return (
    <React.Fragment>
      <Entrypoint />
      {newElement}
    </React.Fragment>
  )
}

export const wrapRootElement = ({ element }) => {
  return <AppState>{element}</AppState>
}
