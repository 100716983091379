import React, { useReducer } from "react"

import AppContext from "./app-context"
import {
  appReducer,
  CHANGE_MENU,
  FETCH_PAGE_BEGIN,
  FETCH_PAGE_END,
  UPDATE_LAST_URL,
} from "./app-reducers"

const AppState = props => {
  const [appState, dispatch] = useReducer(appReducer, { menuActive: undefined })

  const changeMenu = menuActive => {
    setTimeout(() => {
      dispatch({ type: CHANGE_MENU, menuActive })
    }, 0)
  }

  const fetchPageBegin = () => {
    setTimeout(() => {
      dispatch({ type: FETCH_PAGE_BEGIN })
    }, 0)
  }

  const fetchPageEnd = () => {
    setTimeout(() => {
      dispatch({ type: FETCH_PAGE_END })
    }, 0)
  }

  const updateLastUrlLoading = lastUrlLoading => {
    setTimeout(() => {
      dispatch({ type: UPDATE_LAST_URL, lastUrlLoading })
    }, 0)
  }

  return (
    <AppContext.Provider
      value={{
        menuActive: appState.menuActive,
        pageLoading: appState.pageLoading,
        lastUrlLoading: appState.lastUrlLoading,
        changeMenu,
        fetchPageBegin,
        fetchPageEnd,
        updateLastUrlLoading,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppState
