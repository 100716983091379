export const CHANGE_MENU = "CHANGE_MENU"
export const FETCH_PAGE_BEGIN = "FETCH_PAGE_BEGIN"
export const FETCH_PAGE_END = "FETCH_PAGE_END"
export const UPDATE_LAST_URL = "UPDATE_LAST_URL"

const changeMenu = (menuActive, state) => {
  const newState = { ...state, menuActive }
  return newState
}

const fetchPageBegin = state => {
  const newState = { ...state, pageLoading: true }
  return newState
}

const fetchPageEnd = state => {
  const newState = { ...state, pageLoading: false }
  return newState
}

const updateLastUrlLoading = (lastUrlLoading, state) => {
  const newState = { ...state, lastUrlLoading }
  return newState
}

export const appReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_MENU:
      return changeMenu(action.menuActive, state)
    case FETCH_PAGE_BEGIN:
      return fetchPageBegin(state)
    case FETCH_PAGE_END:
      return fetchPageEnd(state)
    case UPDATE_LAST_URL:
      return updateLastUrlLoading(action.lastUrlLoading, state)
    default:
      return state
  }
}
