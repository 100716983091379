import React from "react"

export default React.createContext({
  menuActive: "",
  pageLoading: false,
  lastUrlLoading: null,
  changeMenu: () => {},
  fetchPageBegin: () => {},
  fetchPageEnd: () => {},
  updateLastUrlLoading: lastUrlLoading => {},
})
